<template>
    <div>
        <Loading v-if="!requested" />
        <div class="notifications" v-if="requested">
            <div class="main">
                <navbar />
                <div class="wrapper">
                    <div class="header">
                        <h1>Notifications</h1>
                    </div>
                    <div class="body">
                        <div class="notification" v-for="n in 5" :key="n">
                            <div class="notif-img">
                                <v-card width="80">
                                    <v-img height="80" src="https://fomy-assets-sandbox.oss-ap-southeast-3.aliyuncs.com/images/2/2021/05/ahrqoFr0ltL0FFfekphfheL8gxir8h.jpg"></v-img>
                                </v-card>
                            </div>
                            <div class="notif-detail">
                                <div class="notif-title">Lorem ipsum dolor sit amet.</div>
                                <div class="notif-body">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente similique quos ab,</div>
                                <div class="notif-time">23 mins ago</div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <NavFooter v-if="requested" />
    </div>
</template>
<script>
import NavFooter from '@/components/common/NavFooter.vue'
import Loading from "@/view/Loading.vue";
import Navbar from "./Navbar.vue"
import { mdiCalendarMonth } from '@mdi/js';


export default {
    name: "Notifications",
    components:{
        NavFooter,
        Loading,
        Navbar
    },
    data(){
        return{
            icons:{
                mdiCalendarMonth
            },
            requested: false,
        }

    },
    created(){
        setTimeout(()=>{
            this.requested=true;
        },300)
    },
    methods:{

    }
}

</script>

<style lang="scss" scoped>
@import "@/components/common/css/global.scss";
@import "@/components/common/css/common.scss";

.wrapper{
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 40px 60px 40px;
    @media screen and (max-width: $bp-mobile){
        padding: 0 14px 60px 14px;
    }
}
.header{
    padding: 2rem 0 1rem 0;
}

.notification{
    display: flex;
    align-items: flex-start;
    padding: 1rem 0;
    border-bottom: 1px solid #e5e5e5;
}
.notif-detail{
    margin-left: 1rem;
}
.notif-title{
    font-weight: 500;
    font-size: 1rem;
}
.notif-body{
    font-size: .9rem;
    margin-top: 0.2rem;
}
.notif-time{
    font-size: 0.8rem;
    color: $gray4;
    margin-top: 0.2rem;
}

</style>